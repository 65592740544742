import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { LinearProgress } from "@mui/material";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Button,
  Modal,
  Select,
  SelectItem,
  TextInput,
} from "@carbon/react";
import { useNavigate } from "react-router-dom";
import apiL from "../../../api/apiList";

import { useSearchParams } from "react-router-dom";
import Layout from "../layout";
import { Reportdata } from "./reportData";
import Lottie from "react-lottie";
import animationData from "./Animation - 1707826343372.json";
export default function Report(props) {
  let [searchParams] = useSearchParams();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [isBangla, setisBangla] = useState(false);
  const aid = searchParams.get("id") || null;
  const uid = searchParams.get("code");
  const pid = localStorage.getItem("id");
  const [rep, setrep] = useState([]);
  const [avg, setavg] = useState(0);
  const [clarity, setclarity] = useState(0);
  const [fData, setfData] = useState({});
  async function getreport() {
    let id = searchParams.get("code");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      dashboard: "BUSINESS",
      assessment_id: parseInt(aid),
    });
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.getDashboardBusiness + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let newArray = dsa.result.filter(function (el) {
            return el.block != "STRATEGY, PLANNING AND SUCCESSION";
          });
          setrep(newArray);
          let totals = 0;
          let clar = 0;
          dsa.result.map((e) => {
            totals = e.yes + totals;
            clar = e.unsure + clar;
          });
          totals = totals / (dsa.result.length - 1);
          clar = 100 - clar / (dsa.result.length - 1);
          setavg(totals);
          setclarity(clar);
        }
      })
      .catch((error) => console.log("error", error));
  }
  const business = () => {
    if (avg < 20) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              Business Overview
            </Typography>
          </div>
          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progress"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
            </div>
          </div>
          <div className="result">
          {isBangla?     <p>{fData?.AssessmentB?.BusinessOverview?.Overview}</p>:     <p>{fData?.Assessment?.BusinessOverview?.Overview}</p>}
       
          </div>
        </div>
      );
    } else if (avg < 40) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              Business Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progorange"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
            </div>
          </div>
          <div className="result">
          {isBangla?     <p>{fData?.AssessmentB?.BusinessOverview?.Overview}</p>:     <p>{fData?.Assessment?.BusinessOverview?.Overview}</p>}
          </div>
        </div>
      );
    } else if (avg < 60) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              Business Overview
            </Typography>
          </div>
          {}
          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progyellow"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
            </div>
          </div>
          <div className="result">
          {isBangla?     <p>{fData?.AssessmentB?.BusinessOverview?.Overview}</p>:     <p>{fData?.Assessment?.BusinessOverview?.Overview}</p>}
          </div>
        </div>
      );
    } else if (avg < 80) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              Business Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="proglightgreen"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
            </div>
          </div>
          <div className="result">
            {/* <h3></h3> */}
            {isBangla?     <p>{fData?.AssessmentB?.BusinessOverview?.Overview}</p>:     <p>{fData?.Assessment?.BusinessOverview?.Overview}</p>}
          </div>
        </div>
      );
    } else if (avg <= 100) {
      return (
        <div className="report-card reprot-left-top">
          <div className="heading">
            <Typography variant="h4B" color="neutral.b800">
              Business Overview
            </Typography>
          </div>

          <div className="report-progress">
            <LinearProgress
              variant="determinate"
              color="progdarkgreen"
              value={avg}
            />
            <div className="report-progress-content">
              <h1>{parseInt(avg)}%</h1>
            </div>
          </div>
          <div className="result">
          {isBangla?     <p>{fData?.AssessmentB?.BusinessOverview?.Overview}</p>:     <p>{fData?.Assessment?.BusinessOverview?.Overview}</p>}
          </div>
        </div>
      );
    }
  };
  const [rows, setrows] = useState([]);
  async function getAssesment() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.asseslist + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let data = dsa.result.assessments.reverse();
          data = data.filter(function (el) {
            return el.id != aid;
          });
          setrows(data);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function compareNow() {

    if (comp == "") {
      setcompErr("Please select a version");
    } else {
      setcompErr(null);
      navigate(
        `/portal/compare/?id=${aid}&cid=${comp}&acode=${uid}&ccode=${ccode}`
      );
    }
  }
  const [comp, setcomp] = useState("");
  const [ccode, setccode] = useState("");
  const [modalAddUser, setmodalAddUser] = useState(false);
  const [compErr, setcompErr] = useState(null);
  const [modalAdd, setmodalAdd] = useState(false);
  const [modalAddReport, setmodalAddReport] = useState(false);
  const navigate = useNavigate();
  async function getData() {
    var myHeaders = new Headers();
    var raw = "";
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_SERVER_PANORAMA}/admin/report/summary/${aid}${uid}`,
      requestOptions
    )
      .then(async (response) => {
        let dta = await response.text();
        setfData(JSON.parse(dta));
      })
      .then((result) => result)
      .catch((error) => console.log("error", error));
  }
  function getBlockMessage(blockName) {
    
    const block = isBangla? fData?.AssessmentB?.Block?.Blocks.find(
      (item) => item.Block === blockName
    ): fData?.Assessment?.Block?.Blocks.find(
      (item) => item.Block === blockName
    )

    // Return the block message if found, or a default message if not found
    return block ? (
      block.Message
    ) : (
      <Lottie options={defaultOptions} height={100} width={100} />
    );
  }
  useEffect(() => {
    getreport();
    getData();
    getAssesment();
  }, []);

  return (
    <>
      {" "}
      <Modal
        open={modalAddReport}
        className="userAddModal"
        modalHeading="Generating Report"
        primaryButtonText="Cancel"
        // secondaryButtonText="Cancel"
        onRequestClose={() => setmodalAddReport(false)}
        onRequestSubmit={() => setmodalAddReport(false)}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Your report is being generated. Please enable pop-ups to start
          downloading your assessment report. Please wait.
        </p>
      </Modal>
      <Modal
        open={modalAdd}
        className="userAddModal"
        modalHeading="Compare Report"
        primaryButtonText="Confirm"
        secondaryButtonText="Cancel"
        onRequestClose={() => setmodalAdd(false)}
        onRequestSubmit={() => compareNow()}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          Please select the report you want to compare it with.
        </p>
        <Select
          id="select-1"
          defaultValue="placeholder-item"
          labelText="Report"
          onChange={(e) => {
            let s = e.target.value.split(",");
            setcomp(s[0]);
            setccode(s[1]);
          }}
        >
          <SelectItem disabled hidden value="" text="Select Report" selected />
          {rows.map((e) => {
            return (
              <SelectItem
                value={e.id + "," + e.profile_code}
                text={e.create_date + " (B Report)" + " " + e.name}
              />
            );
          })}
        </Select>
        {compErr == null ? (
          <div></div>
        ) : (
          <p style={{ marginBottom: "1rem", color: "red" }}>{compErr}</p>
        )}
      </Modal>
      <Layout
        assesID={aid}
        modal={setmodalAddUser}
        setmodalAddReport={setmodalAddReport}
        setmodalAdd={setmodalAdd}
        aid={aid}
        uid={uid}
        server={process.env.REACT_APP_SERVER}
        isBangla={isBangla}
        setisBangla={setisBangla}
      />
      <Modal
        open={modalAddUser}
        className="userAddModal"
        modalHeading="Upgrade Plan"
        primaryButtonText="Upgrade Plan"
        secondaryButtonText="Cancel"
        onRequestClose={() => setmodalAddUser(false)}
        onRequestSubmit={() => navigate("/portal/plans")}
        size="sm"
      >
        <p style={{ marginBottom: "1rem" }}>
          The Detailed Report is not available for your current plan. Visit our{" "}
          <a style={{cursor:'pointer'}} onClick={() => navigate("/portal/plans/")}>Plans & Billings</a> to
          unlock your detailed report.
        </p>
      </Modal>
      <div className="innerPortal bg-grey-plan">
        <div className="report">
          <div className="report-left">
            <Grid container spacing={0}>
              <Grid item sm={12} md={12} lg={5} xl={5}>
                {business()}
                {/* <div className="report-card reprot-left-bottom">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Solutions
                    </Typography>
                  </div>
                  <ul className="solution-list">
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                    <li className="single-solution">
                      <div
                        className="single-solution-top"
                        style={{
                          background: "url(/solution-bg.svg)",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        <h4>Business Consulting</h4>
                        <p>
                          Management consulting is the practice of providing
                          consulting services to organizations to improve their
                          performance or in any way...
                        </p>
                      </div>
                      <div className="single-solution-bottom">
                        <img src="/logopanaroma.svg" />
                        <div className="single-solution-text">
                          <p>Panorama</p>
                          <p>Management Advisory Services</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> */}
                {/* <div className="report-card reprot-right-top">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Assesment Metrics
                    </Typography>
                  </div>
                  <div className="report-purpose">
                    <h3>WHAT IS YOUR PURPOSE</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                    <h3>WHY IS IT IMPORTANT</h3>
                    <p>
                      Take a look at the following to understand how much you
                      understand regarding the following things surrounding your
                      own Business purpose. Take a look at the following to
                      understand how much you understand regarding the following
                      things surrounding your own Business purpose.{" "}
                    </p>
                  </div>
                  <div className="btns">
                    <div className="report-card-btn">
                      <img src="/download.svg" />
                      <p>Download Section Report</p>
                    </div>
                  </div>
                </div> */}
                <div className="report-card reprot-right-top">
                  <div className="heading">
                    <Typography variant="h4B" color="neutral.b800">
                      Assesment Metrics
                    </Typography>
                  </div>
                  <div className="report-purpose mnb">
                    <div className="boxsa">
                      <div
                        className="roundbox"
                        style={{ backgroundColor: "#dc4d43" }}
                      ></div>
                      <p> Poor</p>
                    </div>
                    <div className="boxsa">
                      <div
                        className="roundbox"
                        style={{ backgroundColor: "#F89826" }}
                      ></div>
                      <p> Bad</p>
                    </div>
                    <div className="boxsa">
                      <div
                        className="roundbox"
                        style={{ backgroundColor: "#efda1f" }}
                      ></div>
                      <p> Average</p>
                    </div>
                    <div className="boxsa">
                      <div
                        className="roundbox"
                        style={{ backgroundColor: "#0abd4f" }}
                      ></div>
                      <p> Good</p>
                    </div>
                    <div className="boxsa">
                      <div
                        className="roundbox"
                        style={{ backgroundColor: "#156935" }}
                      ></div>
                      <p> Excellent</p>
                    </div>
                  </div>
                  <div className="btns">
                    {/* <div className='report-card-btn'>
                                        <img src="/idea.svg" />
                                        <p>Knowledge Center</p>
                                    </div> */}
                    {/* <div
												className="report-card-btn"
												onClick={async () => {
													setmodalAddReport(true)
													var requestOptions = {
														method: "GET",
														redirect: "follow",
													};

													fetch(
														`https://server.panoramamas.com/admin/pdf/?id=${aid}&uid=${uid}`,
														requestOptions
													)
														.then((response) => response.json())
														.then((result) => {
															window.open(result.url); 
															setmodalAddReport(false)
														
														})
														.catch((error) => console.log("error", error));
												}}
											>
												<img src="/download.svg" />
												<p>Download Section Report</p>
											</div>
											<div
												className="report-card-btn"
												// onClick={() => setmodalAdd(true)}
												style={{cursor:'not-allowed'}}
											>
												<img src="/locksmall.svg" />
												<p>Compare Report</p>
											</div> */}
                  </div>
                </div>
              </Grid>
              <Grid item sm={12} md={12} lg={7} xl={7}>
                <div className="all-report-cards">
                  {rep.map((n) => {
                    if (n.yes >= 0 && n.yes <= 20) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progress"
                            value={n.yes == 0 ? 1 : n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{getBlockMessage(n.block)}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 21 && n.yes <= 40) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progorange"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{getBlockMessage(n.block)}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 41 && n.yes <= 60) {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progyellow"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{getBlockMessage(n.block)}</span>
                          </div>
                        </div>
                      );
                    } else if (n.yes >= 61 && n.yes <= 80) {
         
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="proglightgreen"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{getBlockMessage(n.block)}</span>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="report-card reprot-progress-item">
                          <div className="report-progress-item-top">
                            <p>{n.block}</p>
                            <p>{Math.round(parseInt(n.yes))}%</p>
                          </div>
                          <LinearProgress
                            variant="determinate"
                            color="progdarkgreen"
                            value={n.yes}
                          />
                          <div className="report-progress-item-bottom">
                            <span>{getBlockMessage(n.block)}</span>
                          </div>
                        </div>
                      );
                    }
                  })}
                  {/* <div className='report-card-premium'>
                                        <div className="assesmentbottomleft">
                                            <h4>Don't miss out on the full experience</h4>
                                            <p>Enjoy access to all features including analytics, reporting, and priority support for your team.</p>
                                        </div>
                                        <div className="assesmentbottomright">
                    
                                            <Button kind="primary" className="btnss" size="md">Explore all Packages</Button>
                                        </div>
                                    </div> */}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </>
  );
}
