import React, { useEffect, useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextInput, Select, SelectItem, Checkbox, Button } from "@carbon/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonGL from "../../Components/reUse/buttonGl";
import ButtonFB from "../../Components/reUse/buttonFb";
import { useForm } from "react-hook-form";
import { signIn_schema } from "../../Components/signup/signschema";
import apiL from "../../api/apiList";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../userContext";
import { ConstructionOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
export default function SignIn() {
	const { user, setUser } = useContext(UserContext);
	const navigate = useNavigate();
	const [btnSub, setbtnSub] = useState(false);
	const id = localStorage.getItem("id");
	const [error, seterror] = useState("");
	let [searchParams] = useSearchParams();
	const code = searchParams.get("activation_code") || null;
	let onb = localStorage.getItem("onboard");
	const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

	const togglePasswordVisibility = () => {
	  setShowPassword(!showPassword);
	};
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(signIn_schema),
	});
	const onSubmit = async (data) => {
		setbtnSub(true);
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		let raw;
		code == null
			? (raw = JSON.stringify({
					jsonrpc: "2.0",
					params: {
						db: process.env.REACT_APP_DB_NAME,
						login: data.email,
						password: data.password,
					},
			  }))
			: (raw = JSON.stringify({
					jsonrpc: "2.0",
					params: {
						db: process.env.REACT_APP_DB_NAME,
						login: data.email,
						password: data.password,
						activation_code: code,
					},
			  }));

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.signin, requestOptions)
			.then(async (response) => {
				let rfa = localStorage.getItem("id")
				let dsa = await response.json();
				if(dsa.result.profile_id !==rfa){
					localStorage.clear()
				}
				if (dsa.result.db == process.env.REACT_APP_DB_NAME) {
					localStorage.setItem("id", dsa.result.profile_id);
					localStorage.setItem("uid", dsa.result.uid);
					localStorage.setItem("pid", dsa.result.plan_id);
					localStorage.setItem("vd", dsa.result.is_validated);
					localStorage.setItem("name", dsa.result.name);
					localStorage.setItem("onboard", dsa.result.is_onboarded);
					localStorage.setItem("invited", dsa.result.is_invited);

					// localStorage.setItem("prof",dsa.result.first_name+" "+dsa.last_name)
					setUser({
						id: dsa.result.profile_id,
						uid: dsa.result.uid,
						pid: dsa.result.plan_id,
						emailValidation: dsa.result.is_validated,
						planId: dsa.result.plan_id,
					});
					if (dsa.result.is_onboarded == true) {
						async function getProfile(id) {
							var myHeaders = new Headers();
							myHeaders.append("Content-Type", "application/json");
							var raw = JSON.stringify({});
							var requestOptions = {
								method: "POST",
								credentials: "include",
								headers: {
									"Content-Type": "application/json",
								},
								body: raw,
								redirect: "follow",
							};
							fetch(apiL.getProfile + id, requestOptions)
								.then(async (response) => {
									let ds = await response.json();
									let sk = ds.result?.personal_info?.image_type;

									if (sk == false) {
										localStorage.setItem(
											"image",
											ds.result.personal_info.photo
										);
									} else {
										localStorage.setItem(
											"image",
											sk + ds.result.personal_info.photo
										);
									}
								})
								.catch((error) => console.log("error", error));
						}
						getProfile(dsa.result.profile_id);
						navigate("/portal/index");
					
					} else {
					
						if (
							dsa.result.is_onboarded == false &&
							dsa.result.profile_id !== null
						) {
							navigate("/onboard/?phase=2");
						} else {
						
							navigate("/onboard/?phase=0");
						}
					}
				} else {
					seterror(dsa.result.error);
					setbtnSub(false);
				}
			})
			.catch((error) => console.log("error", error));
	};
	async function getProfile() {
	
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		var raw = JSON.stringify({});
		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,
			redirect: "follow",
		};
		fetch(apiL.getProfile + id, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				if (
					(dsa.result.personal_info.user_code == id) &
					(dsa.result.is_onboarded == true)
				) {
					navigate("/portal/index");
				}
			})
			.catch((error) => console.log("error", error));
	}
	useEffect(() => {
		getProfile();
	}, []);
	return (
		<div style={{ minHeight: "100vh", minWidth: "100%" }}>

			<Grid container>
				<Grid item xl={5} lg={5} md={12} className="bg">
					<div className="pd">
						<div className="pdt">
							<img src="/Your paragraph text (700 x 1000 px).webp" />
						</div>
					</div>
				</Grid>
				<Grid item xl={7} lg={7} md={12}>
					<div className="cont">
						<img src="/logoFull.svg" alt="image" width={180} height={120} />
						<div className="form">
							{/* <Typography variant="base" color="initial">
								Welcome
							</Typography> */}
							<Typography variant="h4" color="neutral.b600">
								Sign in 
							</Typography>
						</div>
						<form onSubmit={handleSubmit(onSubmit)}>
							<div className="cont1">
								<div className="txtc">
									<TextInput
										size="lg"
										className="txtF"
										id="text-input-1"
										type="text"
										labelText="Email*"
										placeholder="Email"
										invalid={errors.email ? true : false}
										invalidText={errors.email?.message}
										{...register("email", { required: true })}
									/>
								</div>
								<div className="txtc" style={{ position: "relative" }}>
      <TextInput
        size="lg"
        className="txtF"
        id="text-input-1"
        type={showPassword ? "text" : "password"}
        labelText="Password*"
        placeholder="Password"
        invalid={errors.password ? true : false}
        invalidText={errors.password?.message}
        {...register("password", { required: true })}
      />
      <Button
        onClick={togglePasswordVisibility}
        style={{
          position: "absolute",
          right: "-53px",
          top: "25px", 
          background: "none",
          border: "none",
          cursor: "pointer",
		    outline: "none", // Prevent default blue border highlight
          boxShadow: "none",
		    WebkitTapHighlightColor: "transparent"
        }}
      >
        {showPassword ? <VisibilityOff style={{ color: "gray" }}  /> : <Visibility style={{ color: "gray", paddingRight:"0px" }}  />}
      </Button>
    </div>

								<div style={{ display: "flex", paddingTop: "12px" }}>
									<div style={{ paddingRight: "8px" }}>
										<Button type="submit" disabled={btnSub}>
											Sign in
										</Button>
									</div>

									{/* <div style={{ paddingRight: "8px" }}>
                    <ButtonGL />
                  </div>
                  <div style={{ paddingRight: "8px" }}>
                    <ButtonFB />
                  </div> */}
								</div>
								<div style={{ paddingTop: "8px" }}>
									<Typography variant="small" color="error.main">
										{error}
									</Typography>
								</div>
								<div className="txtccc">
                <Typography
										variant="base"
										color="initial"
										style={{ marginTop: "10px", display: "block" }}
									>
										Forgot password?{" "}
										<span
											style={{ color: "#0065FF", cursor: "pointer" }}
											onClick={() => navigate("/reset_password")}
										>
											Reset your password
										</span>.
									</Typography>
									<Typography
										variant="base"
										color="initial"
										style={{ marginTop: "10px", display: "block" }}
									>
										New to Panorama?{" "}
										<span
											style={{ color: "#0065FF", cursor: "pointer" }}
											onClick={() => navigate("/signup")}
										>
											Create a new account
										</span>.
									</Typography>
									<div className="ghg">
										<Typography variant="small" color="initial">
										By continuing, you agree to our {" "}
											<a href="https://panoramamas.com/privacy_policy">
												Privacy Policy
											</a>{" "}
											and{" "}
											<a href="https://panoramamas.com/terms&conditions">
												Terms & Conditions.
											</a>{" "}
											
										</Typography>
									</div>
								</div>
							</div>
						</form>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
