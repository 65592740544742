import React from "react";
import Container from "@mui/material/Container";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
	Tabs,
	TabList,
	Tab,
	TabPanels,
	TabPanel,
	Button,
	Modal,
	TextInput,
} from "@carbon/react";
import UserInfoTab from "./UserInfoTab";
import BusinessInfoTab from "./BusinessInfoTab";
import AssesmentInfoTab from "./AssesmentInfoTab";
import apiL from "../../../api/apiList";
import { useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function UserInfo({ data, notify }) {
	const [image, setImage] = useState({ preview: "/john_doe.png", raw: "" });
	const navigate = useNavigate();
	const [newInput, setnewInput] = useState("");
	const [oldInput, setoldInput] = useState("");
	const [errors, seterrors] = useState(null);
	const [showPassword, setShowPassword] = useState(false); // State to manage password visibility

	const togglePasswordVisibility = () => {
	  setShowPassword(!showPassword);
	};
	const handleChange = async (e) => {
		if (e.target.files.length) {
			let id = localStorage.getItem("id");
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			const toBase64 = (file) =>
				new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.readAsDataURL(file);
					reader.onload = () => resolve(reader.result);
					reader.onerror = reject;
				});
			let b64 = await toBase64(e.target.files[0]);
			let star = true;
			let count = 0;
			let b644=""
			let bstring=""
			if (b64.includes("data:image/png;base64,")){
				b644=b64.replace("data:image/png;base64,","")
				bstring="data:image/png;base64"
			}
			if (b64.includes("data:image/png;base64,")){
				b644=b64.replace("data:image/png;base64,","")
				bstring="data:image/png;base64,"
			}
			if (b64.includes("data:image/png;base64,")){
				b644=b64.replace("data:image/png;base64,","")
				bstring="data:image/png;base64,"
			}
			 
			while (star == false) {
			
				if (b64[0] == ",") {
					star = false;
				} else {
					count = count + 1;
				}
			}
			let raw = JSON.stringify({ file: b644,image_type:bstring });
	
			var requestOptions = {
				method: "POST",
				credentials: "include",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};
			fetch(apiL.updatePicture + id, requestOptions)
				.then((response) => response.text())
				.then((result) => console.log(result))
				.catch((error) => console.log("error", error));
			setImage({
				preview: URL.createObjectURL(e.target.files[0]),
				raw: e.target.files[0],
			});
      localStorage.setItem("image",(bstring+b644))
		}
	};
	const Logout = () => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.logout, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
				const keysToRemove = ['vd', 'id', 'pid', 'uid', 'image', 'onboard', 'name', 'invited'];

// Iterate over each key and remove the item from localStorage
keysToRemove.forEach(key => {
  localStorage.removeItem(key);
});
				navigate("/signin");
			})
			.catch((error) => console.log("error", error));
	};
	const ChangePassword = () => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");

		var raw = JSON.stringify({
			jsonrpc: "2.0",
			params: {
				db: process.env.REACT_APP_DB_NAME,
				old_password: oldInput,
				new_password: newInput,
			},
		});

		var requestOptions = {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: raw,

			redirect: "follow",
		};
		fetch(apiL.changePassword, requestOptions)
			.then(async (response) => {
				let dsa = await response.json();
			
				if (dsa.result.error) {
					seterrors(dsa.result.error);
				}
				if (dsa.result.message) {
					seterrors(dsa.result.message);
					setmodalAddUser(false);
				}
			})
			.catch((error) => console.log("error", error));
	};
	const [modalAddUser, setmodalAddUser] = useState(false);
 useEffect(() => {
 let img= localStorage.getItem("image")
 setImage({preview:img})
 }, [])
 
	return (
		<div className="xcvbv">
			<div className="custom_container">
				<Modal
					open={modalAddUser}
					className="userAddModal"
					modalHeading="Change Password"
					primaryButtonText="Save"
					secondaryButtonText="Cancel"
					onRequestClose={() => setmodalAddUser(false)}
					onRequestSubmit={() => ChangePassword()}
					size="sm"
				>
					<p style={{ marginBottom: "1rem" }}>
					Please enter your current password and the new password you would like to set.
					</p>
					<TextInput
						data-modal-primary-focus
						id="text-input-1"
						type={showPassword ? "text" : "password"}
						labelText="Old Password"
						placeholder="Old Password"
						style={{ marginBottom: "1rem" }}
						value={oldInput}
						onChange={(e) => setoldInput(e.target.value)}
					/>
					   <Button
  onClick={togglePasswordVisibility}
  style={{
    position: "absolute",
right:"1px",
paddingRight:"20px",
	top: window.innerWidth >= 1897 ? "89px" : "107px",
    transform: "translateY(-50%)",
    background: "none",
    border: "none",
    cursor: "pointer",
    outline: "none",
    boxShadow: "none",
    WebkitTapHighlightColor: "transparent",

  }}
>
  {showPassword ? (
    <VisibilityOff style={{ color: "gray" }} />
  ) : (
    <Visibility style={{ color: "gray" }} />
  )}
</Button>

					<TextInput
						data-modal-primary-focus
						id="text-input-1"
					
						type={showPassword ? "text" : "password"}
						labelText="New Password"
						placeholder="New Password"
						style={{ marginBottom: "1rem" }}
						value={newInput}
						onChange={(e) => setnewInput(e.target.value)}
					/>
							      <Button
        onClick={togglePasswordVisibility}
        style={{
          position: "absolute",
          right: "1px",
		  paddingRight:"20px",
		  top: window.innerWidth >= 1897 ? "169px" : "187px",
          transform: "translateY(-50%)",
          background: "none",
          border: "none",
          cursor: "pointer",
           outline: "none", 
          boxShadow: "none",
           WebkitTapHighlightColor: "transparent"
        }}
      >
        {showPassword ? (
          <VisibilityOff style={{ color: "gray" }} /> // Icon color set to black
        ) : (
          <Visibility style={{ color: "gray" }} /> // Icon color set to black
        )}
      </Button>
					{errors == null ? (
						<div></div>
					) : (
						<p style={{ marginBottom: "1rem", color: "red" }}>{errors}</p>
					)}
					
				</Modal>
				<Grid container spacing={0}>
					<Grid sm={12} md={12} lg={12} xl={12}>
						<div className="userInfoFixed">
							{/* <div className="profileImage">
								<label htmlFor="upload-button">
									<div>
										<img
											src={image.preview}
											alt="dummy"
											width="300"
											height="300"
										/>
									</div>
								</label>
								<input
									type="file"
									id="upload-button"
									style={{ display: "none" }}
									onChange={handleChange}
								/>
							</div> */}
							<div className="profileInfo">
								<div>
									{" "}
									<Typography variant="largeB" color="neutral.b600">
										{data?.personal_info?.first_name +
											" " +
											data?.personal_info?.last_name}
									</Typography>
								</div>
								<div>
									<Typography variant="small" color="neutral.b600">
										{data?.personal_info?.email}
									</Typography>
								</div>
								<div className="type">
									<Typography variant="xsmall" color="neutral.b600">
										User
									</Typography>
								</div>
								<div style={{ paddingTop: "24px", display: "flex" }}>
									<Button
										kind="primary"
										size="sm"
										style={{ marginRight: "8px" }}
										onClick={() => {
											setmodalAddUser(true);
											seterrors("");
											setoldInput("");
											setnewInput("");
										}}
									>
										Change Password
									</Button>
									{/* <Button kind="primary" size="sm" onClick={() => Logout()}>
										Logout
									</Button> */}
								</div>
							</div>
						</div>
						<div className="userTabs">
							<Tabs>
								<TabList activation="manual" aria-label="List of tabs">
									<Tab>Personal Info</Tab>
									<Tab>Business Info</Tab>
								</TabList>
								<TabPanels>
									<UserInfoTab data={data} notify={notify} />
									<BusinessInfoTab data={data} notify={notify} />
									{/* <AssesmentInfoTab /> */}
								</TabPanels>
							</Tabs>
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
}
