import React, { useEffect, useState } from "react";
import Layout from "../../Components/Portal/layout";
import { useSearchParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import apiL from "../../api/apiList";
import { Button } from "@carbon/react";
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  TextInput,
  TextArea,
  SelectItem,
  Tag,
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell,
} from "@carbon/react";
import { useLocation } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import {  Modal } from "@carbon/react";
import Select from "react-select";
import Lottie from "react-lottie";
import { useSocket } from "../../SocketContext";
import animationData from "../../Components/Portal/dashboard/Animation - 1707685579199.json";
import socketIO from 'socket.io-client';
export default function ChatbotMenu({socket,setSocket}) {

  const navigate = useNavigate();
  const location = useLocation();

  let [searchParams] = useSearchParams();
  const [compType, setcompType] = useState("null");
  const [titleChat, settitleChat] = useState("");
  const [viewMode, setviewMode] = useState(false);
  const [originalData, setoriginalData] = useState([]);
  const [rows, setrows] = useState([]);
  const [rowss, setrowss] = useState([]);
  const [rowsT, setrowsT] = useState([]);
  const [allData, setallData] = useState([]);
  const [UserData, setUserData] = useState({})
  let onb = localStorage.getItem("onboard");
  const [logOutSes, setlogOutSes] = useState(false);
  const [loading, setLoading] = useState(true);
  const [qdata, setqdata] = useState(null);
  const profile = localStorage.getItem("id");
  const dashboard = searchParams.get("dashboard");
  const [selected, setSelected] = useState([]);
  const [das, setdas] = useState("BUSINESS");
  const [modalP, setmodalP] = useState(false);
  const [modalDid, setmodalDid] = useState("");
  const headers = ["Chat Title", "Created On", "Updated On", "Action"];
  function generateKey() {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
    return result;
  }
  function filterItemsWithStateDone(originalData) {
    // Filter original data to include only items with state "done"
    const filteredData = originalData.filter(
      (assessment) => assessment.state === "done"
    );

    // Return the filtered data
    return filteredData;
  }
  function findItemsByNameAndSort(namesArray, originalData) {
    const result = [];

    namesArray.forEach((nameObj) => {
      // Filter original data to find matching names
      const matchedItems = originalData.filter(
        (assessment) => assessment.name === nameObj.value
      );

      // For each matched item, push a new object to the result array
      matchedItems.forEach((item) => {
        result.push({
          id: item.id,
          name: item.name,
          profile_code: item.profile_code,
          email: item.email,
          company: item.company,
          create_date: item.create_date,
          state: item.state,
        });
      });
    });

    // Sort the result array by create_date
    result.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));

    return result;
  }
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  function filterAndFormatData(data) {
    // Count occurrences of each name
    const counts = data.reduce((acc, curr) => {
      acc[curr.name] = (acc[curr.name] || 0) + 1;
      return acc;
    }, {});

    // Filter names that are repeated at least twice
    const filteredNames = Object.keys(counts).filter(
      (name) => counts[name] >= 2
    );

    // Collect unique names and format data
    const uniqueFilteredData = [
      ...new Set(
        data
          .filter((assessment) => filteredNames.includes(assessment.name))
          .map((assessment) => assessment.name)
      ),
    ].map((name) => ({
      value: name,
      label: name,
    }));

    return uniqueFilteredData;
  }
  function createArrayFromValues(values) {
    const transformedArray = values.map((item) => ({
      name: item.label,
      id: item.value,
    }));
    return transformedArray;
  }
  function createLabelValueArray(userProfiles) {
    return userProfiles.map((profile) => ({
      label: `${profile.name} - ${profile.create_date}`,
      value: profile.id,
    }));
  }
  async function getAssesment() {
    let id = localStorage.getItem("id");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,
      redirect: "follow",
    };
    fetch(apiL.asseslist + id, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        if (dsa.result) {
          let fdata = filterItemsWithStateDone(dsa.result.assessments);
          setoriginalData(fdata);
          let data = createLabelValueArray(fdata);
          let x = filterAndFormatData(fdata);
          setrowsT(x);
          setrows(data);
        } else if (dsa.error && dsa.error.message == "Odoo Session Expired") {
          navigate("/signin");
        }
      })
      .catch((error) => console.log("error", error));
  }
  async function getData() {
    // setLoading(true);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_SERVER_PANORAMA}/admin/compare/items/?profileCode=${profile}&server=${process.env.REACT_APP_SERVER}&DB=${process.env.REACT_APP_DB_NAME}`,
      requestOptions
    )
      .then((response) => response.json())
      .then(async (result) => {
        let p = await result;
        setrowss(p);
      })
      .catch((error) => console.error(error));
  }
  async function initChatSession() {
    // setLoading(true);
    let x = createArrayFromValues(selected);
    let raw = {
      data: x,
      title: titleChat,
    };

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_SERVER_PANORAMA}/admin/compare/init/?profile=${profile}&server=${process.env.REACT_APP_SERVER}&DB=${process.env.REACT_APP_DB_NAME}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        let p = await result;

        if (p == "Session Created") {
          getData();
          setSelected([]);
          settitleChat("");
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  }
  async function DeleteChatSession() {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: "",
      redirect: "follow",
    };
    fetch(
      `${process.env.REACT_APP_SERVER_PANORAMA}/admin/compare/item/${modalDid}`,
      requestOptions
    )
      .then((response) => response.text())
      .then(async (result) => {
        getAssesment();
        getData();
        setmodalP(false)
      })
      .catch((error) => console.error(error));
  }
  const Logout = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({});

    var requestOptions = {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: raw,

      redirect: "follow",
    };
    fetch(apiL.logout, requestOptions)
      .then(async (response) => {
        let dsa = await response.json();
        setlogOutSes(false);
        navigate("/signin");
        const keysToRemove = [
          "vd",
          "id",
          "pid",
          "uid",
          "image",
          "onboard",
          "name",
          "invited",
        ];
        keysToRemove.forEach((key) => {
          localStorage.removeItem(key);
        });
      })
      .catch((error) => console.log("error", error));
  };
  if (onb === "false") {
    Logout();
    navigate("/signin");
  }
  useEffect(() => {
    getAssesment();
    getData();
    const socket = socketIO.connect`${process.env.REACT_APP_SERVER_PANORAMA}`;
    setSocket(socket)
  }, []);
  //   useEffect(() => {
  //     if (selected.length == 0) {
  //       setallData({});
  //     }
  //   }, [selected]);
  // useEffect(() => {
  //   getData();
  // }, [das]);

  return (
    <>
      <Layout
        das={das}
        setdas={setdas}
        setallData={setallData}
        viewMode={viewMode}
        compType={compType}
        logOutSes={logOutSes} setlogOutSes={setlogOutSes}
      />
                <Modal
            open={logOutSes}
            className="userAddModal"
            modalHeading="You will be logged out"
            primaryButtonText="Logout"
            secondaryButtonText="Cancel"
            onRequestClose={() => setlogOutSes(false)}
            onRequestSubmit={() => Logout()}
            size="sm"
          >
            <p style={{ marginBottom: "1rem" }}>
              Are you sure you want to log out of your Panorama account?
            </p>
          </Modal>
                  <Modal
                  danger 
              open={modalP}
              className="userAddModal"
              modalHeading="Delete Chat"
              primaryButtonText="OK"
              secondaryButtonText="Cancel"
              onRequestClose={() => {
                setmodalP(false);
              }}
              onRequestSubmit={() => {
DeleteChatSession()
              }}
              size="xs"
            >
              <p style={{ marginBottom: "1rem" }}>
                You are about to delete the chat session
              </p>

            </Modal>
      {viewMode ? (
        <div className="ptl">
          <div className="innerPortal bg-grey-plan">
            <div className="report"></div>
          </div>
        </div>
      ) : (
        <div className="ptl">
          <div className="innerPortal bg-grey-plan">
            <div className="report">
              <div className="xcgz">
                <div className="consultCard" style={{ opacity: "1" }}>
                  <div className="headerPlan">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1822_11)">
                        <path
                          d="M10 6.25H7.5C6.83696 6.25 6.20107 6.51339 5.73223 6.98223C5.26339 7.45107 5 8.08696 5 8.75V23.75C5 24.413 5.26339 25.0489 5.73223 25.5178C6.20107 25.9866 6.83696 26.25 7.5 26.25H14.6212"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M22.5 15V8.75C22.5 8.08696 22.2366 7.45107 21.7678 6.98223C21.2989 6.51339 20.663 6.25 20 6.25H17.5"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 6.25C10 5.58696 10.2634 4.95107 10.7322 4.48223C11.2011 4.01339 11.837 3.75 12.5 3.75H15C15.663 3.75 16.2989 4.01339 16.7678 4.48223C17.2366 4.95107 17.5 5.58696 17.5 6.25C17.5 6.91304 17.2366 7.54893 16.7678 8.01777C16.2989 8.48661 15.663 8.75 15 8.75H12.5C11.837 8.75 11.2011 8.48661 10.7322 8.01777C10.2634 7.54893 10 6.91304 10 6.25Z"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 13.75H15"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10 18.75H13.75"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.5 21.875C17.5 22.7038 17.8292 23.4987 18.4153 24.0847C19.0013 24.6708 19.7962 25 20.625 25C21.4538 25 22.2487 24.6708 22.8347 24.0847C23.4208 23.4987 23.75 22.7038 23.75 21.875C23.75 21.0462 23.4208 20.2513 22.8347 19.6653C22.2487 19.0792 21.4538 18.75 20.625 18.75C19.7962 18.75 19.0013 19.0792 18.4153 19.6653C17.8292 20.2513 17.5 21.0462 17.5 21.875Z"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M23.125 24.375L26.25 27.5"
                          stroke="#2461FF"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1822_11">
                          <rect width="30" height="30" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>

                    <Typography variant="small" color="neutral.b800">
                      Pan AI Chat
                    </Typography>
                  </div>
                  <div className="contentCard2">
    
                    <div className="compCard">
                      <div className="selectSpace">
                        <div style={{ paddingTop: "12px" }}>
                         <p style={{fontSize:"16px" ,paddingBottom:"12px", fontWeight:"normal", color:"#666666"}}>Please name your chat for future reference</p>
                          <TextInput
                            size="xl"
                            className="txtF"
                            id="text-input-1"
                            type="text"
                            // labelText="Please name your chat for future reference"
                            
                            placeholder="Chat Title"
                            value={titleChat}
                            onChange={(e) => settitleChat(e.target.value)}
                          />
                        </div>
                        <div style={{ paddingTop: "24px" }}>
                        <p style={{ paddingBottom: "12px",fontSize:'16px',fontWeight:"400" }}>
                    Please select the assessments you want to use for the chat
                    </p>
                        <MultiSelect
                              options={rows}
                              value={selected}
                              onChange={setSelected}
                             labelledBy="Select Report"
                             overrideStrings={{
                              selectSomeItems: "Select Reports",
                          
                           
                            }}
                              
                            />
                           
                            
                        </div>
                      </div>

                      <div className="lti">
                        <Lottie
                          options={defaultOptions}
                          height={320}
                          width={320}
                        />
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      initChatSession();
                    }}
                    className="btnss vcb"
                    style={{ maxWidth: "100%", width: "100%" }}
                    kind="primary"
                    size="lg"
                    disabled={
                      selected?.length == 0 || titleChat == "" ? true : false
                    }
                  >
                    Create Session
                  </Button>
                </div>
              </div>
              {rows.length > 0 ? (
                <div style={{ paddingBottom: "80px" }}>
                  <Table size="lg" useZebraStyles={false}>
                    <TableHead>
                      <TableRow>
                        {headers?.map((header) => (
                          <TableHeader id={generateKey} key={header}>
                            {header}
                          </TableHeader>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
          
                      {rowss?.length > 0
                        ? rowss?.map((row) => (
                            <TableRow key={row._id}>
                              <TableCell key={generateKey()}>
                                {row.title}
                              </TableCell>
                              <TableCell key={generateKey()}>
                                {row.createdAt}
                              </TableCell>
                              <TableCell key={generateKey()}>
                                {row.updatedAt}
                              </TableCell>
                              <TableCell key={generateKey()}>
                                <span
                                  onClick={() => {
                                    socket.emit("newUser", {
                                      profile:profile,
                                      socketID: socket.id,
                                    });
                                    navigate(`/portal/chatbot/${row._id}`);
                                  }}
                                  style={{
                                    color: "#0065FF",
                                    cursor: "pointer",
                                  }}
                                >
                                  Open
                                </span>{" "}
                                <span
                                  onClick={() => {
                                    setmodalP(true)
                                    setmodalDid(row._id)
                                  }}
                                  style={{
                                    color: "#ED2939",
                                    cursor: "pointer",
                                  }}
                                >
                                  Delete
                                </span>{" "}
                              </TableCell>
                            </TableRow>
                          ))
                        : ""}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
