import React, { useState } from "react";
import { Button } from "@carbon/react";
import Typography from "@mui/material/Typography";
import banglaData from "./banglaData";
export default function Qcard({ data, totalq, stotalq, i, isBangla }) {
  const [answer, setanswer] = useState(data[1]);
  async function handleAnswer(ans) {
    let dsa = totalq;
    dsa[i][1] = ans;
    stotalq(dsa);
    setanswer(ans);
  }
  function findElementByQuestion(question) {
    let x = banglaData.find((item) => item.Question === question);
    return x.BQuestion;
  }

  return (
    <div className={answer == null ? "assMentQcard Qcards" : "assMentQcard"}>
      <div className="ques">
        {isBangla ? (
          <Typography variant="large" color="neutral.b300">
            {i + 1 + ". "}
            {findElementByQuestion(data[0])}
          </Typography>
        ) : (
          <Typography variant="large" color="neutral.b300">
            {i + 1 + ". "}
            {data[0]}
          </Typography>
        )}
      </div>
      <div className="bdr"></div>
      <div className="qsbtn">
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "yes" ? " selected" : "")}
            onClick={() => handleAnswer("yes")}
          >
            {isBangla ? "হ্যাঁ" : "Yes"}
          </Button>
        </div>
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "no" ? " selected" : "")}
            onClick={() => handleAnswer("no")}
          >
            {isBangla ? "না" : "No"}
          </Button>
        </div>
        <div className="qsbt">
          <Button
            kind="primary"
            size="md"
            className={"btnss" + (answer === "unsure" ? " selected" : "")}
            onClick={() => handleAnswer("unsure")}
          >
            {isBangla ? "অনিশ্চিত" : "Unsure"}
          </Button>
        </div>
      </div>
    </div>
  );
}
